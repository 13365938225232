import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-search-params-polyfill'
import 'date-time-format-timezone'
import 'react-dates/initialize'

import 'utils/googlePlaceAutocomplete'

import 'stylesheets/normalize.css'
import 'react-dates/lib/css/_datepicker.css'

import React from 'react'
import ReactDOM from 'react-dom'

import { datadogLogs } from '@datadog/browser-logs'

import App from 'components/App'

datadogLogs.init({
  clientToken: 'pub1c09a91535f5ba2fa778c377d464f452',
  env: process.env.NODE_ENV,
  forwardErrorsToLogs: false,
  service: 'dashboard',
  sessionSampleRate: 100,
  site: 'datadoghq.com',
})


ReactDOM.render(<App />, document.getElementById('app'))
