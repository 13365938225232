import Cookies from 'js-cookie'
import { datadogLogs } from '@datadog/browser-logs'


export default function logSignUpSuccess(message, { from, user }) {
  datadogLogs.logger.info(`SignUpSuccess (${ message })`, {
    cookies: Cookies.get(),
    dataLayer: window.dataLayer,
    from,
    user,
  })
}
